<template>
    <section>
      <section class="el-centered" v-if="isLoadingSubmit">
        <emd-pagarme-loader />
      </section>
      <section v-else>
        <h2 class="emd-text _size_section el-chunk">{{ $t('billing') }}</h2>
        <template v-if="address.is_editable">
          <div class="eu-flex eu-column eu-row_md">
            <div class="eu-flex-item eu-basis_12">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.zip_code.error, '_state_success': zipCodeNotFound === false }">
                <label class="emd-label" :class="{ '_required': address.fields.zip_code.validation.required }">{{ $t('field.zipCode') }}</label>
                <div class="emd-input-group">
                  <the-mask v-if="address.type === 'brazilian'" placeholder="00000-000" :masked="false" class="emd-input" mask="#####-###" type="tel" v-model="address.fields.zip_code.value" @input="searchZipCode" />
                  <input v-else class="emd-input" type="tel" v-model="address.fields.zip_code.value" @input="updateRequiredFields" />
                  <i class="emd-text _color_subtle uil uil-spinner" v-if="localLoading" spin />
                </div>
                <p v-if="address.fields.zip_code.error" class="emd-input-feedback">{{ $t(`error.${address.fields.zip_code.error}`) }}</p>
                <p v-if="address.fields.zip_code.error === 'length'" class="emd-input-feedback">{{ $tc(`error.${address.fields.zip_code.error}`, address.fields.zip_code.validation.length) }}</p>
                <p v-if="zipCodeNotFound" class="emd-input-feedback">{{ $t(`error.zipCodeNotFound`) }}</p>
                <a v-if="address.type === 'brazilian'" class="emd-input-link eu-display_inline-block" href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank" rel="noopener noreferrer">Não sei meu CEP</a>
              </div>
            </div>
          </div>
          <div v-if="address.type === 'brazilian'" class="eu-flex eu-column eu-row_md">
            <div class="eu-flex-item eu-basis_12">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.street.error }">
                <label class="emd-label" :class="{ '_required': address.fields.street.validation.required }">{{ $t('field.street') }}</label>
                <input class="emd-input" type="text" placeholder="Rua, avenida, praça..." v-model="address.fields.street.value" @input="address.fields.street.error = false" />
                <p v-if="address.fields.street.error" class="emd-input-feedback">{{ $t(`error.${address.fields.street.error}`) }}</p>
                <p class="emd-input-feedback">{{ $t('helper.street') }}</p>
              </div>
            </div>
            <div class="eu-flex-item eu-basis_6_md">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.number.error }">
                <label class="emd-label" :class="{ '_required': address.fields.number.validation.required }">{{ $t('field.number') }}</label>
                <input class="emd-input" placeholder="123, 12A, 13B..." type="tel" v-model="address.fields.number.value" @input="address.fields.number.error = false" />
                <p v-if="address.fields.number.error" class="emd-input-feedback">{{ $t(`error.${address.fields.number.error}`) }}</p>
              </div>
            </div>
            <div class="eu-flex-item eu-basis_6_md">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.complement.error }">
                <label class="emd-label" :class="{ '_required': address.fields.complement.validation.required }">{{ $t('field.complement') }}</label>
                <input class="emd-input" type="text" placeholder="Apto., bloco, ponto de referência…" v-model="address.fields.complement.value" @input="address.fields.complement.error = false" />
              </div>
            </div>
            <div class="eu-flex-item eu-basis_12">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.neighborhood.error }">
                <label class="emd-label" :class="{ '_required': address.fields.neighborhood.validation.required }">{{ $t('field.neighborhood') }}</label>
                <input class="emd-input" placeholder="Digite o nome do bairro" type="text" v-model="address.fields.neighborhood.value" @input="address.fields.neighborhood.error = false" />
                <p v-if="address.fields.neighborhood.error" class="emd-input-feedback">{{ $t(`error.${address.fields.neighborhood.error}`) }}</p>
              </div>
            </div>
          </div>
          <div v-else class="eu-flex eu-column eu-row_md">
            <div class="eu-flex-item eu-basis_12">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.line_1.error }">
                <label class="emd-label" :class="{ '_required': address.fields.line_1.validation.required }">{{ $t('field.line1') }}</label>
                <input class="emd-input" type="text" v-model="address.fields.line_1.value" @input="address.fields.line_1.error = false" />
                <p class="emd-input-feedback">{{ $t('helper.line1') }}</p>
              </div>
            </div>
            <div class="eu-flex-item eu-basis_12">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.line_2.error }">
                <label class="emd-label" :class="{ '_required': address.fields.line_2.validation.required }">{{ $t('field.line2') }}</label>
                <input class="emd-input" type="text" v-model="address.fields.line_2.value" @input="address.fields.line_2.error = false" />
                <p class="emd-input-feedback">{{ $t('helper.line2') }}</p>
              </div>
            </div>
          </div>
          <div class="eu-flex eu-column eu-row_md">
            <div class="eu-flex-item eu-basis_12">
              <div class="emd-form-group" :class="{ '_state_error': address.fields.city.error }">
                <label class="emd-label" :class="{ '_required': address.fields.city.validation.required }">{{ $t('field.city') }}</label>
                <input class="emd-input" placeholder="Digite o nome da cidade" type="text" v-model="address.fields.city.value" @input="address.fields.city.error = false" />
                <p v-if="address.fields.city.error" class="emd-input-feedback">{{ $t(`error.${address.fields.city.error}`) }}</p>
              </div>
              <div class="emd-form-group" :class="{ '_state_error': address.fields.state.error }">
                <label class="emd-label" :class="{ '_required': address.fields.state.validation.required }">{{ $t('field.state') }}</label>
                <input class="emd-input eu-uppercase" v-mask="['SS']" type="text" placeholder="UF" v-model="address.fields.state.value" @input="address.fields.state.error = false" />
                <p v-if="address.fields.state.error === 'length'" class="emd-input-feedback">{{ $tc(`error.${address.fields.state.error}`, address.fields.state.validation.length) }}</p>
                <p v-if="address.fields.state.error" class="emd-input-feedback">{{ $t(`error.${address.fields.state.error}`) }}</p>
              </div>
              <div v-if="address.type === 'global'" class="emd-form-group" :class="{ '_state_error': address.fields.country.error }">
                <label class="emd-label" :class="{ '_required': address.fields.country.validation.required }">{{ $t('field.country') }}</label>
                <input class="emd-input eu-uppercase" v-mask="['SS']" type="text" v-model="address.fields.country.value" @input="address.fields.country.error = false" />
                <p v-if="address.fields.country.error === 'length'" class="emd-input-feedback">{{ $tc(`error.${address.fields.country.error}`, address.fields.country.validation.length) }}</p>
              </div>
            </div>
          </div>
        </template>
        <billing-address-card  v-else />
      </section>
    </section>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import { isEmpty } from '@/utils'
import { EmdPagarmeLoader } from 'emerald-vue/src/components'
import BillingAddressCard from './BillingAdressCard.vue'

export default {
  name: 'BillingAddress',
  components: {
    TheMask,
    BillingAddressCard,
    EmdPagarmeLoader
  },
  computed: {
    isLoadingSubmit () {
      return this.$store.getters.loadingSubmit
    },
    address: {
      get () {
        return this.$store.getters.address
      }
    },
    localLoading () {
      return this.$store.getters.localLoading
    }
  },
  data () {
    return {
      zipCodeNotFound: null,
      canSearchZipcode: true
    }
  },
  created () {
    if (!isEmpty(this.address.fields.zip_code.value)) {
      this.canSearchZipcode = false
    }
  },
  methods: {
    updateRequiredFields () {
      this.$store.dispatch('UPDATE_REQUIRED_FIELDS')
      this.address.fields.zip_code.error = false
    },
    searchZipCode (e) {
      this.updateRequiredFields()
      Object.keys(this.address.fields).forEach((key) => {
        this.address.fields[key].error = false
      })
      this.zipCodeNotFound = null
      this.fetchZipCode(e)
    },
    fetchZipCode (zipCode) {
      if (zipCode && zipCode.length === 8 && this.canSearchZipcode) {
        var params = {
          zipCode: zipCode
        }
        this.$store.dispatch('TOGGLE_LOCAL_LOADING', { isLoading: true })
        this.$store.dispatch('GET_ZIPCODE', params)
          .then((response) => {
            this.onFetchSuccess(response)
          })
          .catch(() => {
            this.onFetchError()
          })
      } else {
        this.canSearchZipcode = true
      }
    },
    onFetchSuccess (response) {
      this.canSearchZipcode = true
      this.zipCodeNotFound = false
      this.address.fields.street.value = response.street
      this.address.fields.number.value = ''
      this.address.fields.complement.value = ''
      this.address.fields.neighborhood.value = response.district
      this.address.fields.city.value = response.city
      this.address.fields.state.value = response.state
      this.$store.dispatch('TOGGLE_LOCAL_LOADING', { isLoading: false })
    },
    onFetchError () {
      this.canSearchZipcode = true
      this.address.fields.street.value = ''
      this.address.fields.number.value = ''
      this.address.fields.complement.value = ''
      this.address.fields.neighborhood.value = ''
      this.address.fields.city.value = ''
      this.address.fields.state.value = ''
      this.zipCodeNotFound = true
      this.$store.dispatch('TOGGLE_LOCAL_LOADING', { isLoading: false })
    }
  }
}
</script>

<style>
.el-centered {
  height: 50vh !important
}
</style>

<i18n>
{
  "pt-BR": {
    "shipping": "Entrega",
    "address": "Endereço",
    "recipient": "Destinatário",
    "billing": "Cobrança",
    "zipCodeUnknown": "Não sei meu CEP",
    "billingEqualShipping": "O endereço de cobrança é igual ao de entrega",
    "field": {
      "zipCode": "CEP",
      "street": "Logradouro",
      "number": "Número",
      "complement": "Complemento",
      "neighborhood": "Bairro",
      "line1": "Linha 1",
      "line2": "Linha 2",
      "city": "Cidade",
      "state": "Estado",
      "country": "País"
    },
    "helper": {
      "street": "digite a rua, avenida ou similar",
      "line1": "digite seu logradouro, número e bairro",
      "line2": "digite o complemento do seu endereço"
    },
    "error": {
      "required": "esse campo é obrigatório",
      "zipCodeNotFound": "Não conseguimos encontrar esse CEP. Revise os dados e continue se estiver tudo certo.",
      "length": "preencha com 1 caracter | preencha com {count} caracteres"
    }
  },
  "en-US": {
    "shipping": "Shipping",
    "address": "Address",
    "recipient": "Recipient",
    "billing": "Billing",
    "zipCodeUnknown": "I don't know my Postal Code",
    "billingEqualShipping": "Use same Billing Address",
    "field": {
      "zipCode": "Zip code",
      "street": "Street",
      "number": "Number",
      "complement": "Complement",
      "neighborhood": "Neighborhood",
      "line1": "Line 1",
      "line2": "Line 2",
      "city": "City",
      "state": "State",
      "country": "Country"
    },
    "helper": {
      "street": "enter your street, avenue or similar",
      "line1": "enter your street, number and neighborhood",
      "line2": "enter your address complements"
    },
    "error": {
      "required": "this field is required",
      "zipCodeNotFound": "We were unable to find this zip code. Review the data and continue if everything is ok.",
      "length": "fill in with 1 character | fill in with {count} characters"
    }
  }
}
</i18n>
